import React, { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../components/shared/Button';
import ModalContext from '../contexts/ModalContext';
import BaseModal from './BaseModal';
import deleteTimelineEntry from '../api/timeline/deleteTimelineEntry';

const DeleteTimelineEntryModal = () => {
  const { t } = useTranslation();
  const { emitter, events } = useContext(ModalContext);
  const [open, setOpen] = useState(false);
  const [entryId, setEntryId] = useState<string>('');
  const [userId, setUserId] = useState<string>('');

  useEffect(() => {
    const unbind = emitter.on(
      events.DELETE_TIMELINE_ENTRY_MODAL,
      ({ id, timelineEntryId }: { id: string; timelineEntryId: string }) => {
        setOpen(true);
        setEntryId(timelineEntryId);
        setUserId(id);
      },
    );

    return () => unbind();
  }, [emitter, events]);

  const getTitle = () => t('modals.deleteTimeline.title');

  const handleEntryDelete = () => {
    (async () => {
      await deleteTimelineEntry({ userId, id: entryId });
    })();
    setOpen(false);
  };

  const handleDelete = (
    <>
      <Button onClick={handleEntryDelete}>
        {t('modals.deleteTimeline.delete')}
      </Button>
    </>
  );

  return (
    <BaseModal title={getTitle()} state={[open, setOpen]} action={handleDelete}>
      <p className="leading-loose">{t('modals.deleteTimeline.message')}</p>
    </BaseModal>
  );
};

export default memo(DeleteTimelineEntryModal);
