import React, { memo, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import BaseModal from './BaseModal';
import ModalContext from '../contexts/ModalContext';
import Button from '../components/shared/Button';

import useJournalEntry from '../hooks/useJournalEntry';

const DeleteJournalEntryModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [isEntry, setIsEntry] = useState<boolean>();
  const { deleteJournalEntry } = useJournalEntry();
  const [handleEntryDelete, setHandleEntryDelete] = useState<
    (title: string, id: string) => void
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  >(() => {});

  const { emitter, events } = useContext(ModalContext);

  useEffect(() => {
    const unbind = emitter.on(events.DELETE_JOURNAL_ENTRY_MODAL, (args) => {
      setOpen(true);
      setId(args.id);
      setTitle(args.title);
      setIsEntry(args.isEntry);
      setHandleEntryDelete(() => args.deleteAction);
    });

    return () => unbind();
  }, [emitter, events]);

  const handleDeleteEntry = () => {
    if (isEntry) {
      deleteJournalEntry(id);
    } else {
      handleEntryDelete(title, id);
    }

    setOpen(false);
    navigate('/app/journal');
  };

  const handleDeleteAction = (
    <>
      <Button onClick={handleDeleteEntry}>{t('shared.buttons.confirm')}</Button>
    </>
  );

  return (
    <BaseModal
      title={t('modals.deleteEntry.title')}
      state={[open, setOpen]}
      action={handleDeleteAction}
    >
      <p className="leading-loose">{t('modals.deleteEntry.message')}</p>
    </BaseModal>
  );
};

export default memo(DeleteJournalEntryModal);
