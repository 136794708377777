import React, { useEffect } from 'react';
import { Slide, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import ModalRegistrar from '../../modals/ModalRegistrar';
import ROOT_URL from '../../constants/url';

const Wrapper = ({ children }) => {
  useEffect(() => {
    toast.configure({
      role: 'alert',
      hideProgressBar: true,
      transition: Slide,
      closeButton: false,
      position: 'bottom-right',
      pauseOnFocusLoss: false,
    });
  }, []);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>CareerSocius Resume Builder</title>
        <meta name="description" content="CareerSocius Resume Builder" />
        <link rel="canonical" href={ROOT_URL} />
        <meta property="og:url" content={ROOT_URL} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="CareerSocius Resume Builder" />
        <meta property="og:image" content="https://i.imgur.com/YY2B7mE.png" />
      </Helmet>

      {children}

      <ModalRegistrar />
    </>
  );
};

export default Wrapper;
