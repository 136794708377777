import useDispatch from './useAppDispatch';
import useSelector from './useAppSelector';

import deleteJournalEntryFirebase from '../api/journal/deleteJournalEntry';

import {
  journalEntryCreateNewItem,
  journalEntryLoadExistingItem,
  journalEntrySubmit,
  journalEntryUpdateDetails,
  selectJournalEntry,
  selectJournalEntryIsUpdating,
  setJournalEntry,
} from '../redux/slices/journalEntrySlice';
import { JournalEntry } from '../types/journalItems/JournalEntry';
import useUser from './useUser';
import getJournalEntry from '../api/journal/getJournalEntry';
import deleteTimelineEntries from '../api/timeline/deleteTimelineEntries';

export default function useJournalEntry() {
  const dispatch = useDispatch();
  const journalEntry = useSelector(selectJournalEntry);
  const isUpdating = useSelector(selectJournalEntryIsUpdating);

  const { user } = useUser();

  const loadJournalEntry = async (id: string) => {
    dispatch(journalEntryLoadExistingItem({ journalId: id }));
    const data = await getJournalEntry(id);
    return data;
  };

  const createJournalEntry = (id: string) => {
    if (user == null) {
      return;
    }

    dispatch(journalEntryCreateNewItem({ journalId: id }));
  };

  const updateJournalEntryDetails = ({
    title,
    content,
    createdAt,
  }: {
    title: string;
    content: string;
    createdAt: number;
  }) => {
    if (user == null) {
      return;
    }
    dispatch(journalEntryUpdateDetails({ title, content, createdAt }));
  };

  const updateJournalEntry = (
    journalEntryUpdated: Omit<JournalEntry, 'user'>,
  ) => {
    if (user == null) {
      return;
    }
    dispatch(
      setJournalEntry({
        journalEntry: { ...journalEntryUpdated, user: user.uid },
      }),
    );
  };

  const submitJournalEntry = () => {
    if (user == null) {
      return;
    }
    dispatch(journalEntrySubmit());
  };

  const deleteJournalEntry = async (resourceId: string) => {
    if (user == null || resourceId == null) {
      return;
    }
    deleteJournalEntryFirebase(resourceId);
    deleteTimelineEntries({ userId: user?.uid, resourceId });
  };

  return {
    loadJournalEntry,
    createJournalEntry,
    updateJournalEntry,
    updateJournalEntryDetails,
    deleteJournalEntry,
    submitJournalEntry,
    journalEntry,
    isUpdating,
  };
}
