import { navigate } from 'gatsby';
import React, { memo, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkedinIcon, LinkedinShareButton } from 'react-share';
import { BsDownload } from 'react-icons/bs';
import Button from '../components/shared/Button';
import BaseModal from './BaseModal';
import reactToJpeg from '../utils/reactToJpeg';
import ModalContext from '../contexts/ModalContext';

const ShareModal = ({ modalEvent }: { modalEvent: string }) => {
  const { t } = useTranslation();
  const { emitter } = useContext(ModalContext);
  const [open, setOpen] = useState(false);
  const [imageComponent, setImageComponent] = useState<HTMLDivElement | null>(
    null,
  );
  const [url, setUrl] = useState<string>('');
  const [modalMessage, setModalMessage] = useState<string>('');

  useEffect(() => {
    const unbind = emitter.on(
      modalEvent,
      ({
        node,
        link,
        message,
      }: {
        node: HTMLDivElement;
        link: string;
        message: string;
      }) => {
        setOpen(true);
        setImageComponent(node);
        setUrl(link);
        setModalMessage(message);
      },
    );

    return () => unbind();
  }, [emitter, modalEvent]);

  const handleGoToApp = () => {
    navigate('/app/journal');
    setOpen(false);
  };

  const handleDownload = () => {
    if (imageComponent != null) {
      reactToJpeg(imageComponent);
    }
  };

  const getTitle = () => {
    t('modals.share.title');
  };

  const buttons = (
    <div>
      <Button onClick={handleGoToApp}>{t('modals.share.done')}</Button>
    </div>
  );

  return (
    <BaseModal title={getTitle()} state={[open, setOpen]} action={buttons}>
      <div className="flex flex-col justify-center">
        <p className="leading-loose text-center">{modalMessage}</p>
        <div className="flex justify-center gap-12 my-4">
          <LinkedinShareButton className="self-center" url={url}>
            <LinkedinIcon className="mx-auto" round />
            {t('modals.share.linkedin')}
          </LinkedinShareButton>
          <div className="flex justify-center flex-col my-auto cursor-pointer">
            <BsDownload
              className="self-center"
              onClick={handleDownload}
              size={50}
            />
            {t('modals.share.image')}
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default memo(ShareModal);
