import { toJpeg } from 'html-to-image';

const reactToJpeg = (node: HTMLDivElement) => {
  if (node === null) {
    return;
  }

  toJpeg(node, { cacheBust: true })
    .then((dataUrl) => {
      const link = document.createElement('a');
      link.download = 'myActivity.jpeg';
      link.href = dataUrl;
      link.click();
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
};

export default reactToJpeg;
