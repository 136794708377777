import React from 'react';
import Grid, { GridSize, GridSpacing } from '@material-ui/core/Grid';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import TipList from '../shared/TipList';
import styles from './WorkExperienceTooltip.module.css';

type row = {
  aspect: string;
  verbs: string;
};

type TipProps = {
  tips: string[];
  aspect: string;
  verbs: string;
  rows: row[];
  gridSpacing: GridSpacing;
  gridTipSize: GridSize;
  gridTableSize: GridSize;
};

const WorkExperienceTooltip = ({
  tips,
  rows,
  aspect,
  verbs,
  gridSpacing,
  gridTipSize,
  gridTableSize,
}: TipProps) => (
  <Grid container spacing={gridSpacing} className="p-2">
    <Grid item xs={gridTipSize}>
      <TipList tiplist={tips} />
    </Grid>
    <Grid item xs={gridTableSize}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableCell}>
                <b>{aspect}</b>
              </TableCell>
              <TableCell align="left" className={styles.tableCell}>
                <b>{verbs}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.aspect}>
                <TableCell
                  component="th"
                  scope="row"
                  className={styles.tableCell}
                >
                  {row.aspect}
                </TableCell>
                <TableCell align="left" className={styles.tableCell}>
                  {row.verbs}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
);

export default WorkExperienceTooltip;
