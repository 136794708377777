import { Formik } from 'formik';
import React, { memo } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Input from '../../components/builder/Input';
import ModalEvents from '../../constants/ModalEvents';
import { getFieldProps } from '../../utils';
import DataModal from '../DataModal';
import WorkExperienceTooltip from '../../components/builder/WorkExperienceTooltip';

const initialValues = {
  company: '',
  position: '',
  website: '',
  startDate: '',
  endDate: '',
  summary: '',
};

const WorkModal = () => {
  const { t } = useTranslation();

  function createData(aspect, verbs) {
    return { aspect, verbs };
  }
  const prefix = 'builder.tooltips.workExperienceTips.workSummaryTable.';
  const rows = [
    createData(t(`${prefix}aspect1`), t(`${prefix}verbs1`)),
    createData(t(`${prefix}aspect2`), t(`${prefix}verbs2`)),
    createData(t(`${prefix}aspect3`), t(`${prefix}verbs3`)),
    createData(t(`${prefix}aspect4`), t(`${prefix}verbs4`)),
    createData(t(`${prefix}aspect5`), t(`${prefix}verbs5`)),
    createData(t(`${prefix}aspect6`), t(`${prefix}verbs6`)),
    createData(t(`${prefix}aspect7`), t(`${prefix}verbs7`)),
    createData(t(`${prefix}aspect8`), t(`${prefix}verbs8`)),
    createData(t(`${prefix}aspect9`), t(`${prefix}verbs9`)),
  ];

  const workExperienceSummaryTips = t(
    'builder.tooltips.workExperienceTips.workExperienceSummary',
    {
      returnObjects: true,
    },
  );

  const schema = Yup.object().shape({
    company: Yup.string().required(t('shared.forms.validation.required')),
    position: Yup.string().required(t('shared.forms.validation.required')),
    website: Yup.string().url(t('shared.forms.validation.url')),
    startDate: Yup.date().required(t('shared.forms.validation.required')),
    endDate:
      Yup.string() ||
      Yup.date()
        .when(
          'startDate',
          (startDate, yupSchema) =>
            startDate &&
            yupSchema.min(startDate, t('shared.forms.validation.dateRange')),
        )
        .required(t('shared.forms.validation.required')),
    summary: Yup.string()
      .min(10, t('shared.forms.validation.min', { number: 10 }))
      .required(t('shared.forms.validation.required')),
  });

  return (
    <Formik
      validateOnBlur
      initialValues={initialValues}
      validationSchema={schema}
    >
      {(formik) => (
        <DataModal
          path="work.items"
          name={t('builder.sections.work')}
          event={ModalEvents.WORK_MODAL}
        >
          <div className="grid grid-cols-2 gap-8">
            <Input
              label={t('builder.work.company')}
              className="col-span-2"
              placeholder="Postdot Technologies Pvt. Ltd."
              {...getFieldProps(formik, schema, 'company')}
            />

            <Input
              label={t('shared.forms.position')}
              placeholder="Full Stack Web Developer"
              {...getFieldProps(formik, schema, 'position')}
            />

            <Input
              label={t('shared.forms.website')}
              placeholder="https://"
              {...getFieldProps(formik, schema, 'website')}
            />

            <Input
              type="date"
              label={t('shared.forms.startDate')}
              placeholder="6th August 208"
              {...getFieldProps(formik, schema, 'startDate')}
            />

            <Input
              type="date"
              label={t('shared.forms.endDate')}
              hasPresentField
              tooltip={t(
                'builder.tooltips.workExperienceTips.workExperienceEndDate',
              )}
              placeholder="6th August 208"
              {...getFieldProps(formik, schema, 'endDate')}
            />

            <Input
              type="textarea"
              label={t('shared.forms.summary')}
              tooltip={
                <WorkExperienceTooltip
                  tips={workExperienceSummaryTips}
                  rows={rows}
                  aspect={t(
                    'builder.tooltips.workExperienceTips.workSummaryTable.aspect',
                  )}
                  verbs={t(
                    'builder.tooltips.workExperienceTips.workSummaryTable.verbs',
                  )}
                  gridSpacing={2}
                  gridTipSize={4}
                  gridTableSize={8}
                />
              }
              className="col-span-2"
              largeTooltip
              {...getFieldProps(formik, schema, 'summary')}
            />
          </div>
        </DataModal>
      )}
    </Formik>
  );
};

export default memo(WorkModal);
