import firebase from 'gatsby-plugin-firebase';

const deleteTimelineEntry = async (args: {
  userId: string;
  id: string;
}): Promise<void> => {
  const { userId, id } = args;
  await firebase.database().ref(`timelines/${userId}/${id}`).remove();
};

export default deleteTimelineEntry;
