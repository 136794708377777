import React, { memo, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BaseModal from './BaseModal';
import ModalContext from '../contexts/ModalContext';
import Button from '../components/shared/Button';
import deleteAccount from '../api/user/deleteAccount';
import styles from './DeleteAccountModal.module.css';
import useUser from '../hooks/useUser';

const DeleteAccountModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { emitter, events } = useContext(ModalContext);
  const { logout } = useUser();

  useEffect(() => {
    const unbind = emitter.on(events.DELETE_ACCOUNT_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const handleDeleteAccount = () => {
    (async () => {
      await deleteAccount();
    })();
    setOpen(false);
    logout();
  };

  const handleDelete = (
    <>
      <Button className={styles.button} onClick={handleDeleteAccount}>
        {t('shared.buttons.deleteAccount')}
      </Button>
    </>
  );

  return (
    <BaseModal
      title={t('modals.deleteAccount.title')}
      state={[open, setOpen]}
      action={handleDelete}
    >
      <p className="leading-loose">{t('modals.deleteAccount.message')}</p>
    </BaseModal>
  );
};

export default memo(DeleteAccountModal);
