import React, { memo, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BaseModal from './BaseModal';
import ModalContext from '../contexts/ModalContext';
import Button from '../components/shared/Button';
import useUser from '../hooks/useUser';

const LogoutModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { emitter, events } = useContext(ModalContext);
  const { logout } = useUser();

  useEffect(() => {
    const unbind = emitter.on(events.LOGOUT_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const handleLogout = () => {
    logout();
    setOpen(false);
  };

  const handleLogoutAction = (
    <>
      <Button onClick={handleLogout}>{t('shared.buttons.logout')}</Button>
    </>
  );

  return (
    <BaseModal
      title={t('modals.logout.title')}
      state={[open, setOpen]}
      action={handleLogoutAction}
    >
      <p className="leading-loose">{t('modals.logout.message')}</p>
    </BaseModal>
  );
};

export default memo(LogoutModal);
