import React, { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../components/shared/Button';
import ModalContext from '../contexts/ModalContext';
import useResume from '../hooks/useResume';
import BaseModal from './BaseModal';
import useDispatch from '../hooks/useAppDispatch';
import {
  setPreviewResume,
  setVersionId,
  setPreviousResume,
  setResume,
} from '../redux/slices/resumeSlice';

const RevertVersionModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { emitter, events } = useContext(ModalContext);
  const { revertResumeVersion, resume, previewResume } = useResume();
  const dispatch = useDispatch();

  useEffect(() => {
    const unbind = emitter.on(events.REVERT_VERSION_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const getTitle = () =>
    t('modals.revertVersion.revert', {
      version: previewResume ? previewResume.versionId : '',
    });

  const handleRevertVersion = () => {
    (async () => {
      if (previewResume == null) {
        setOpen(false);
        return;
      }
      await revertResumeVersion(resume.id, previewResume.versionId);
      dispatch(setPreviewResume({ previewResume: null }));
      dispatch(setVersionId({ versionId: previewResume.versionId }));
      dispatch(setResume({ resume: previewResume }));
      dispatch(
        setPreviousResume({ previousResumeVersion: previewResume.versionId }),
      );
    })();
    setOpen(false);
  };

  const handleRevert = (
    <>
      <Button onClick={handleRevertVersion}>
        {t('shared.buttons.revert')}
      </Button>
    </>
  );

  return (
    <BaseModal title={getTitle()} state={[open, setOpen]} action={handleRevert}>
      <p className="leading-loose">{t('modals.revertVersion.message')}</p>
    </BaseModal>
  );
};

export default memo(RevertVersionModal);
