import React, { memo } from 'react';
import AuthModal from './AuthModal';
import RevertVersionModal from './RevertVersionModal';
import ShareModal from './ShareModal';
import ResumeModal from './ResumeModal';
import AwardModal from './sections/AwardModal';
import CertificateModal from './sections/CertificateModal';
import EducationModal from './sections/EducationModal';
import HobbyModal from './sections/HobbyModal';
import ImportModal from './sections/ImportModal';
import LanguageModal from './sections/LanguageModal';
import ProjectModal from './sections/ProjectModal';
import ReferenceModal from './sections/ReferenceModal';
import SkillModal from './sections/SkillModal';
import WorkModal from './sections/WorkModal';
import VolunteerModal from './sections/VolunteerModal';
import ModalEvents from '../constants/ModalEvents';
import DeleteAccountModal from './DeleteAccountModal';
import LogoutModal from './LogoutModal';
import DeleteTimelineEntryModal from './DeleteTimelineEntryModal';
import DeleteJournalEntryModal from './DeleteJournalEntryModal';

const ModalRegistrar = () => {
  return (
    <>
      <AuthModal />
      <ResumeModal />
      <WorkModal />
      <EducationModal />
      <ProjectModal />
      <VolunteerModal />
      <AwardModal />
      <CertificateModal />
      <SkillModal />
      <HobbyModal />
      <LanguageModal />
      <ReferenceModal />
      <ImportModal />
      <RevertVersionModal />
      <DeleteAccountModal />
      <LogoutModal />
      <ShareModal modalEvent={ModalEvents.PIECHART_MODAL} />
      <ShareModal modalEvent={ModalEvents.WPLH_MODAL} />
      <ShareModal modalEvent={ModalEvents.SCURVE_MODAL} />
      <DeleteTimelineEntryModal />
      <DeleteJournalEntryModal />
    </>
  );
};

export default memo(ModalRegistrar);
